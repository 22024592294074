import request from "superagent"
import { performRequest } from './utils'

export function get(username, cb) {
    performRequest(request.get(`${window.config.apiRoot}/api/v1/user/${username}/`), cb);
}

export function getScoresForLanguage(username, language, cb) {
    performRequest(request.get(`${window.config.apiRoot}/api/v1/user/${username}/language/${language}/`), cb);
}

export function updateAppData(app_id, push_data) {
    performRequest(request.post(`${window.config.apiRoot}/api/v1/_me/app/${app_id}/`).send({
        app_type: "web",
        push_data: push_data
    }));
}
