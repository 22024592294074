import React from 'react'
import { connect } from 'react-redux'

const PointsDisplay = ({points}) => (
  <span>{points}</span>
)

const mapStateToProps = (state) => {
  return {
    points: state.points
  }
}

const PointsDisplayContainer = connect(
  mapStateToProps
)(PointsDisplay)

export default PointsDisplayContainer
