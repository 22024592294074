import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"
import { getPoints } from "./points"

export const requestRoll = createRAAWithHandler(
    ActionTypes.ROLL_REQUEST,
    (language) => {
        return request.post(`${window.config.apiRoot}/api/v1/language/${language}/roll/`);
    },
    (action, language) => {
        action.language = language;
        return action;
    },
    (dispatch) => {
        getPoints()(dispatch);
    }
);

export function roll(state={}, action) {
  switch (action.type) {
    case ActionTypes.ROLL_REQUEST:
      let newState = Object.assign({}, state, action);
      return newState;
    default:
      break;
  }
  return state;
}
