import { APIState } from '../api/utils'
import ActionTypes from "../actiontypes"


export function languageCache(state={}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case ActionTypes.LANGUAGE_REQUEST:
            newState[action.name] = APIState.REQUESTED;
            return newState;
        case ActionTypes.LANGUAGE_ADD:
            newState[action.name] = action.data;
            return newState;
        default:
            break;
    }
    return newState;
}
