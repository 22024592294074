import React from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import './LanguageDetailItem.css';


class LanguageDetailItem extends React.Component {
  render() {
    let languageData = this.props.languageData;
    return (
        <Link to={`/language/${languageData.code}/`}>
            <div className="detail-list-item">
                <img
                  src={`/static/images/languages/${languageData.code}.svg`}
                  alt={`icon for ${languageData.code}`}
                />
                <strong>{languageData.name}</strong>
                <div style={{clear: "both"}}/>
            </div>
        </Link>
    );
  }
}


const Container = connect(
    (state, props) => ({}),
    (dispatch) => ({dispatch})
)(LanguageDetailItem);

export default withRouter(Container);
