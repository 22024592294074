import React from 'react'

class GoogleLogin extends React.Component {

    render () {
        return (<div id={`custom-g-signin2-${this.state.id}`}></div>)
    }

    componentWillMount() {
        this.setState({
            // we generate random ids, because the ids must
            // be unique for the login to find.
            id: Math.random()
        });
    }

    componentDidMount () {
        var self = this;
        window.gapi.load('signin2', function () {
            self.renderGoogleLoginButton();
        });
    }

    renderGoogleLoginButton() {
        window.gapi.signin2.render(`custom-g-signin2-${this.state.id}`, {
            'scope': 'email',
            'onsuccess': this.props.onSignIn
        })
    }
}

export default GoogleLogin;
