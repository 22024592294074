import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"

export const getFlashcardSet = createRAAWithHandler(
    ActionTypes.FLASHCARD_SET_REQUEST,
    (language) => {
        return request.get(`${window.config.apiRoot}/api/v1/language/${language}/flashcards/`);
    },
    (action, language) => {
        action.language = language;
        return action;
    }
);

export function flashcardSet(state={}, action) {
    switch (action.type) {
        case ActionTypes.FLASHCARD_SET_REQUEST:
            let newState = Object.assign({}, state);
            newState[action.language] = action;
            return newState;
        default:
            break;
    }
    return state;
}
