import React from 'react'
import { getBorderStyle } from './Card'

function CardSummary({data, highlightColor}) {
    let classes = ["card-summary", getBorderStyle(data.rarity)];
    if (highlightColor) {
        classes.push("highlight-" + highlightColor);
    }
    return (
        <div className={classes.join(" ")}>
            <span className="levelContainer">
                <span className="level">{data.level}</span>
            </span>
            <img src={`/static/images/cards/${data.image}`} alt="card art"/>
        </div>
    );
}

export default CardSummary;
