import React from 'react'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid';
import { LanguageActions } from '../actions/language'
import { getNotifications } from '../actions/notification'
import { APP_NAME } from '../constants'
import LanguageDetailItem from '../components/index/LanguageDetailItem'
import UserNav from '../components/nav/UserNav'
import { withRouter } from 'react-router-dom'

class Index extends React.Component {
    componentDidMount() {
        LanguageActions.getKeys()(this.props.dispatch);
        getNotifications()(this.props.dispatch);
    }

    render() {
        let points = this.props.points;
        let user = this.props.user;
        if (!(user.loggedIn)) {
            return (
                <div className="pure-g">
                    <section className="pure-u-1">
                        <UserNav/>
                    </section>
                </div>
            );
        } else {
            let langElems = [];
            if (this.props.languages !== -1 && this.props.languages) {
                this.props.languages.forEach((l) => (
                    langElems.push(<LanguageDetailItem languageData={l} key={l.code}/>)
                ));
            }
            return (
              <Grid container>
                <Grid item>
                   <h1>Welcome to {APP_NAME}!</h1>
                   <h2>My Points ({points})</h2>
                   <h2>Choose a Language</h2>
                   <div>
                     {langElems}
                   </div>
                </Grid>
              </Grid>
            );
        }
    }
}


const mapStateToProps = (state) => {
  return {
      languages: state.languageCache._all,
      points: state.points,
      user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  }
}

const IndexContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Index)

export default withRouter(IndexContainer)
