import React from 'react'

function DetailListItem({image, title, upperRight, lowerRight}) {
    return (
        <div className="detail-list-item">
            <img src={image} alt="detail item"/>
            <strong>{title}</strong>
            {upperRight ? <div className="upper-right">{upperRight}</div>: ""}
            {lowerRight ? <div className="lower-right">{lowerRight}</div>: ""}
            <div style={{clear: "both"}}/>
        </div>
    );
}

export default DetailListItem;
