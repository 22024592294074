import React from 'react'
import { Link } from 'react-router-dom'

export default function Notification({notification, onNavigate}) {
    const classes = "notification-icon fa fa-2x fa-" + notification.icon;
    const iconElem = <i className={classes} aria-hidden="true"></i>;
    return (
        <li className="notification">
            {iconElem}
            <Link to={notification.link}
                  onClick={onNavigate}>
                {notification.body}
            </Link>
        </li>
    );
}
