import ActionTypes from "../actiontypes"
import { getLanguage, getLanguageKeys } from "../api/language"

export const LanguageActions = {
    get(name) {
        return (dispatch) => {
            dispatch({type: ActionTypes.LANGUAGE_REQUEST, name});
            getLanguage(name, (data) => (
                dispatch({
                    type: ActionTypes.LANGUAGE_ADD,
                    data,
                    name
                })
            ));
        }
    },

    getKeys() {
        return (dispatch) => {
            let name = "_all";
            dispatch({type: ActionTypes.LANGUAGE_REQUEST, name});
            getLanguageKeys((data) => (
                dispatch({
                    type: ActionTypes.LANGUAGE_ADD,
                    data,
                    name
                })
            ));
        }
    }
}
