import request from "superagent"
import { performRequest } from './utils'

export function getLanguage(name, cb) {
    performRequest(request.get(`${window.config.apiRoot}/api/v1/language/${name}/`), cb);
}

export function getLanguageKeys(cb) {
    performRequest(request.get(`${window.config.apiRoot}/api/v1/language/`), cb);
}
