import React from 'react'
import { connect } from 'react-redux'
import { modifyPoints } from '../actions/points'
import ValueInput from '../components/common/ValueInput'

const Admin = ({points, user, addPoints}) => (
    user.loggedIn ? (
        <div className="pure-g">
            <div className="pure-u-1">
                <h1>My Points ({points})</h1>
                <form className="pure-form" onSubmit={addPoints}>
                    <ValueInput name="Add Points" placeholder="points to add"
                                buttonName="Add"
                                onSubmit={addPoints}
                    />
                </form>
            </div>
        </div>
    ) : (
        <div className="pure-g">
            <div className="pure-u-1">
                <h1>Welcome to LanguageGame</h1>
                <p>
                    Logging in...
                </p>
            </div>
        </div>
    )
)

const mapStateToProps = (state) => {
  return {
      points: state.points,
      user: state.user
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addPoints: (value) => {
        dispatch(modifyPoints(parseInt(value, 10)))
    }
  }
}

const AdminContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Admin)

export default AdminContainer
