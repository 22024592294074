import ActionTypes from "../actiontypes"
const BACKGROUND_ROOT = "/static/images/backgrounds/";
export const DEFAULT_IMAGE = "globe.jpg";

export function setBackground(background) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.BACKGROUND_MODIFY,
            background
        });
    };
}

export function background(state=BACKGROUND_ROOT + DEFAULT_IMAGE, action) {
  switch (action.type) {
    case ActionTypes.BACKGROUND_MODIFY:
      return BACKGROUND_ROOT + action.background;
    default:
      break;
  }
  return state;
}
