import {get as getAPI} from "../api/pointsAPI"
import ActionTypes from "../actiontypes"


export function getPoints() {
    return (dispatch) => {
        getAPI((points) => (
            dispatch({
                type: ActionTypes.SET_POINTS,
                points
            })
        ));
    };
}

export function modifyPoints(delta) {
    return {
        type: ActionTypes.MODIFY_POINTS,
        delta
    }
}
