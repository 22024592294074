import React from 'react'
import { connect } from 'react-redux'
import { dismissLightbox } from '../../actions/lightbox'

export function getBorderStyle(rarity) {
    switch (rarity) {
        case "C":
            return "black-border";
        case "U":
            return "silver-border";
        case "R":
            return "gold-border";
        default:
            return "black-border";
    }
}


function Card({data, dispatch}) {
    const onClick = () => {
        window.history.pushState({}, "Combine Inventory", `/inventory/combine/${data.id}`);
        dispatch(dismissLightbox());
    };
    return (
        <div className="card pure-g">
            <h2 className="pure-u-1">{data.name}</h2>
            <section className="pure-g pure-u-1">
                <p className="pure-u-1-3">{data.language}</p>
                <p className="pure-u-1-3">Level {data.level}</p>
                <p className="pure-u-1-3">{data.experience} exp</p>
            </section>
            <p className="pure-u-1">{data.description}</p>
            <img
              className="pure-u-1" src={`/static/images/cards/${data.image}`}
              alt={`card ${data.id}`}
            />
            <button onClick={onClick}
                    className="pure-button pure-button-primary pure-u-1">
                power up
            </button>
        </div>
    );
}

const Container = connect(
    (state, props) => ({data: props.data}),
    (dispatch) => ({dispatch})
)(Card)

export default Container;
