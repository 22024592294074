import request from "superagent"
import { performRequest } from './utils'

export function modify(delta) {
    performRequest(request.post(`${window.config.apiRoot}/api/v1/points/add/`).send({delta}), (data) => {})
}

export function get(cb) {
    performRequest(request.get(`${window.config.apiRoot}/api/v1/points/`), cb);
}
