import React from 'react'
import DetailListItem from '../common/DetailListItem'
import { addFlashcardSet } from '../../actions/flashcards'

function getStats(cardSetInfo, cards) {
    let total = cardSetInfo.keys.length;
    let added = 0;
    let mastered = 0;
    cardSetInfo.keys.forEach((k) => {
        let card = cards[k];
        if (card !== undefined) {
            added += 1;
            if (card.mastered) {
                mastered += 1;
            }
        }
    });
    return {mastered, total, added, enableAdd: added !== total};
}

function CardSetInfo({language, cardSetInfo, cards, dispatch}) {
    let stats = getStats(cardSetInfo, cards);
    let upperRight = null;
    let addCards = (() => {
        addFlashcardSet(language, cardSetInfo.name)(dispatch);
    });
    let addCardsTen = (() => {
        addFlashcardSet(language, cardSetInfo.name, 10)(dispatch);
    });
    if (true || stats.enableAdd) {
        upperRight = (<span>
            <button className="pure-button button-standard"
                    onClick={addCardsTen}>add 10
            </button> <button className="pure-button button-standard"
                    onClick={addCards}>add cards
            </button>
        </span>);
    }
    return (
        <DetailListItem
            image={`/static/images/languages/${language}.svg`}
            title={cardSetInfo.name}
            lowerRight={
                `${stats.added} / ${stats.total} added, ${stats.mastered} / ${stats.total} mastered`
            }
            upperRight={upperRight}
        />
    );
}

export default CardSetInfo;
