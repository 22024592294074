import React from 'react'
import { connect } from 'react-redux'
import { getLevelInfo } from '../actions/level'
import { APIState } from '../api/utils'
import LoadingSpinner from '../components/common/LoadingSpinner'

class LevelDescription extends React.Component {
    componentDidMount() {
        getLevelInfo(this.props.language, this.props.name)(this.props.dispatch);
    }

    render () {
        switch (this.props.data) {
            case APIState.REQUESTED:
            case null:
            case undefined:
                return <LoadingSpinner/>;
            default:
                return (
                    <div className="pure-g">
                        <h1>foo</h1>
                    </div>
                );
        }
    }
}

const Container = connect(
    (state, props) => ({
        language: props.params.language,
        name: props.params.name,
        data: state.levelInfo
    }),
    (dispatch) => ({dispatch})
)(LevelDescription);

export default Container;
