import ActionTypes from "../actiontypes"
import { randomInt } from "../utils"
import { submitCompletedFlashcards } from "./flashcards"
import { modifyPoints } from "./points"
import { getNotifications } from "./notification"
// import diacritics from "diacritics"
// const levenshtein = require('fast-levenshtein');

const MINUTE = 60 * 1000;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const POINTS_PER_CARD = 10;
const ANSWER_COUNT = 10;

export function startLevel(cards, dictionary) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.FLASHCARD_LEVEL_START,
            cards, dictionary
        });
    };
}

export function resetLevel() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.FLASHCARD_LEVEL_RESET,
        });
    };
}

export function submitAnswer(answer) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.FLASHCARD_LEVEL_SUBMIT_ANSWER,
            answer
        });
    }
}

export function nextQuestion() {
    return (dispatch) => {
        dispatch({ type: ActionTypes.FLASHCARD_LEVEL_NEXT_CARD });
    }
}


export function endFlashcards(language, cards) {
    return (dispatch) => {
        submitCompletedFlashcards(language, cards)(dispatch);
        dispatch(modifyPoints(cards.length * POINTS_PER_CARD));
        getNotifications()(dispatch);
        dispatch({
            type: ActionTypes.FLASHCARD_LEVEL_COMPLETE_REQUEST,
        });
    }
}

// function buildStringFromSource(dictionaryEntry) {
//     let word = dictionaryEntry.key;
//     if (dictionaryEntry.ipa !== null) {
//         word += ` (${dictionaryEntry.ipa})`;
//     }
//     return word;
// }

function setupNextQuestion(state) {
    let card = selectCard(state);
    let options = getChoices(card, state.dictionary, ANSWER_COUNT);
    let [question, answer] = Math.random() > 0.5 ? ["source", "target"] : ["target", "source"];
    return {
        dictEntry: state.dictionary.words[card.key],
        key: card.key,
        practice_distance_m: card.practice_distance_m,
        next_practice: (new Date()).toString(),
        options, question, answer
    };
}

function selectCard(state) {
    let randIndex = randomInt(state.remainingCards.length);
    let rawCard = state.remainingCards[randIndex];
    state.remainingCards.splice(randIndex, 1);
    return rawCard;
}

function getChoices(currentCard, dictionary, desiredCount) {
    let choices = [];
    // clone as we'll be modifying this array.
    let dictEntries = Object.keys(dictionary.words);

    while (choices.length < desiredCount - 1) {
        let randIndex = randomInt(dictEntries.length);
        let key = dictEntries[randIndex];
        if (key !== currentCard.key) {
            let choiceEntry = dictionary.words[key];
            dictEntries.splice(randIndex, 1);
            if (choiceEntry === undefined) {
                console.log(`bad choiceEntry for ${key}`);
            }
            choices.push(choiceEntry);
        }
    }
    const randIndex = randomInt(choices.length);
    choices.splice(randIndex, 0, dictionary.words[currentCard.key]);
    return choices;
}

function correctCard(card, state) {
    let next_practice = new Date(
        (new Date()).getTime() + state.currentCard.practice_distance_m * (1000 * 60)
    );
    state.completedCards.push({
        key: state.currentCard.key,
        next_practice: next_practice.toISOString(),
        practice_distance_m: state.currentCard.practice_distance_m * 2
    });
}

function incorrectCard(card, state) {
    // if you get it wrong and it's below 10
    // minutes, you do it immediately (1 minute)
    if (card.practice_distance_m < 10) {
        card.practice_distance_m = 1;
        // if the distance is larger than a day, you
        // can't go below a day.
    } else if (card.practice_distance_m > DAY) {
        card.practice_distance_m = DAY;
    }
    card.practice_distance_m /= 2;
    state.remainingCards.push(card);
}

// function normalize(s) {
//     return diacritics.remove(s.toLowerCase());
// }

function submitCard(answer, state) {
    // let correctAnswer = normalize(state.currentCard.answer);
    // answer = normalize(answer);
    // const distance = levenshtein.get(correctAnswer, answer);
    // let correct = distance == 0;
    // let almost = distance < 3;
    let correct = answer.key === state.currentCard.dictEntry.key;
    if (correct) {
        correctCard(state.currentCard, state);
    } else {
        incorrectCard(state.currentCard, state);
    }
    if (correct) {
        state.currentCard.correct = "correct";
        // } else if (almost) {
        //     state.currentCard.correct = "almost";
    } else {
        state.currentCard.correct = false;
    }
}

export function flashcardsLevel(state = { started: false }, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case ActionTypes.FLASHCARD_LEVEL_RESET:
            newState.started = false;
            newState.complete = false;
            break;
        case ActionTypes.FLASHCARD_LEVEL_START:
            newState.started = true;
            newState.dictionary = action.dictionary;
            newState.remainingCards = Object.values(action.cards);
            newState.completedCards = [];
            if (newState.remainingCards.length > 0) {
                newState.currentCard = setupNextQuestion(newState);
            } else {
                newState.complete = true;
            }
            break;
        case ActionTypes.FLASHCARD_LEVEL_SUBMIT_ANSWER:
            submitCard(action.answer, newState);
            break;
        case ActionTypes.FLASHCARD_LEVEL_NEXT_CARD:
            newState.currentCard = setupNextQuestion(newState);
            break;
        case ActionTypes.FLASHCARD_LEVEL_COMPLETE_REQUEST:
            newState.complete = true;
            break;
        case ActionTypes.FLASHCARD_SUBMIT_REQUEST:
            break;
        default:
            return state;
    }
    return newState;
}
