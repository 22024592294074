import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"

export const getFlashcards = createRAAWithHandler(
    ActionTypes.FLASHCARD_REQUEST,
    (language) => {
        let req = request.get(`${window.config.apiRoot}/api/v1/user/_me/language/${language}/flashcards/`);
        return req;
    },
    (action, language) => {
        action.language = language;
        return action;
    }
);

export const submitCompletedFlashcards = createRAAWithHandler(
    ActionTypes.FLASHCARD_LEVEL_COMPLETE_REQUEST,
    (language, cards) => {
        return request.post(`${window.config.apiRoot}/api/v1/user/_me/language/${language}/flashcards/`)
                      .send({cards});
    },
    (action, language, cards) => {
        action.language = language;
        action.cards = cards;
        return action;
    }
);

export const submitFlashcards = createRAAWithHandler(
    ActionTypes.FLASHCARD_SUBMIT_REQUEST,
    (language, cards) => {
        return request.post(`${window.config.apiRoot}/api/v1/user/_me/language/${language}/flashcards/`)
                      .send({cards});
    },
    (action, language, cards) => {
        action.language = language;
        action.cards = cards;
        return action;
    }
);

export const addFlashcards = createRAAWithHandler(
    ActionTypes.FLASHCARD_ADD_REQUEST,
    (language, options) => {
        return request.put(`${window.config.apiRoot}/api/v1/user/_me/language/${language}/flashcards/`)
                      .send(options);
    },
    (action, language, options) => {
        return action;
    },
    (dispatch, data, language) => {
        getFlashcards(language)(dispatch);
    }
);


export const addFlashcardSet = createRAAWithHandler(
    ActionTypes.FLASHCARD_ADD_SET_REQUEST,
    (language, name, count = 0) => {
        return request.put(`${window.config.apiRoot}/api/v1/user/_me/language/${language}/flashcards/set`)
            .send({name, count});
    },
    (action, language, options) => {
        return action;
    },
    (dispatch, data, language) => {
        getFlashcards(language)(dispatch);
    }
);




export function flashcards(state={}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
      case ActionTypes.FLASHCARD_REQUEST:
        newState[action.language] = action;
        break;
      default:
        break;
    }
    return newState;
}
