import ActionTypes from "../actiontypes"

export function openLightbox(body) {
    return {type: ActionTypes.LIGHTBOX_OPEN, body};
}

export function dismissLightbox() {
    return {type: ActionTypes.LIGHTBOX_DISMISS};
}

export function lightbox(state={}, action) {
    switch (action.type) {
            case ActionTypes.LIGHTBOX_OPEN:
                return {
                    "body": action.body
                };
            case ActionTypes.LIGHTBOX_DISMISS:
                return {};
            default:
                break;
    }
    return state;
}
