import React from 'react'
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux'
import { getCards } from '../actions/cards'
import CardTable from '../components/inventory/CardTable'
import {
    inventorySelectCardAdd,
    inventorySelectCardRemove
} from '../actions/inventorySelect'
import {
    startConsume, consumeSubmit
} from '../actions/consumeCards'
import { confirm } from "../actions/confirm"


class CardConsume extends React.Component {
    componentDidMount() {
        getCards()(this.props.dispatch);
        startConsume(this.props.mainCard)(this.props.dispatch);
    }

    render () {
        let selectedCount = this.props.inventorySelect.selected.length;
        let onClick = selectedCount !== 0 ? this.consumeCards.bind(this) : () => {};
        return (
            <div className="pure-u-1">
                <h1>Choose Cards To Consume</h1>
                <button className="pure-button pure-button-primary pure-u-1"
                        onClick={onClick}
                >
                    Consume {this.props.inventorySelect.selected.length} Cards
                </button>
                <div>
                    <CardTable
                        cards={this.props.cards}
                        onCardClick={this.getCardOnClick.bind(this)}
                        selectHighlightColor={this.getHighlightColor.bind(this)}
                    />
                </div>
            </div>
        );
    }

    consumeCards() {
        let body = (<div>
                <p>Are you sure you want to consume these cards?</p>
        </div>);
        let mainCard = this.props.mainCard;
        let selected = this.props.inventorySelect.selected;
        let dispatch = this.props.dispatch;
        let onConfirm = () => {
            consumeSubmit(mainCard, selected)(dispatch);
            this.props.history.push('/inventory/')
        };
        confirm(
            this.props.dispatch,
            body,
            onConfirm
        );
    }

    getHighlightColor(card) {
        let inventorySelect = this.props.inventorySelect;
        if (inventorySelect.mainCard === card.id) { return "main"; }
        let selected = inventorySelect.selected.indexOf(card.id) !== -1;
        if (selected) { return "selected" };
        return null;
    }

    getCardOnClick(card) {
        if (this.props.mainCard === card.id) {
            return () => {};
        }
        let unselected = this.props.inventorySelect.selected.indexOf(card.id) === -1;
        let action = unselected ? inventorySelectCardAdd : inventorySelectCardRemove;
        this.props.dispatch(action(card.id));
    }
}

const Container = connect(
    (state, props) => ({
        mainCard: props.params.cardId,
        cards: state.cards,
        history: props.history,
        inventorySelect: state.inventorySelect,
    }),
    (dispatch) => ({dispatch})
)(CardConsume);


export default withRouter(Container);
