import React from 'react'
import { connect } from 'react-redux'
import LoadingSpinner from '../common/LoadingSpinner'
import { getNotifications } from '../../actions/notification'
import { openLightbox, dismissLightbox } from '../../actions/lightbox'
import NotificationList from './NotificationList'
import {Notifications } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';


class NotificationIcon extends React.Component {
    componentDidMount() {
        getNotifications()(this.props.dispatch);
    }

    render () {
        if (this.props.notification.payload) {
            if (this.props.notification.payload.length === 0) {
                return <span/>;
            }
            /* todo: make this icon explicitly red */
            return (
                <IconButton tooltip="notifications">
                    <Notifications onClick={this.showNotifications.bind(this)}
                                   color="secondary"/>
                </IconButton>
            );
        }
        return <LoadingSpinner/>;
    }

    showNotifications(e) {
        e.preventDefault();
        const notifications = this.props.notification.payload;
        this.props.dispatch(openLightbox(
            <NotificationList
                notifications={notifications}
                onNavigate={this.closeNotifications.bind(this)}
            />
        ));
    }

    closeNotifications(e) {
        this.props.dispatch(dismissLightbox());
    }
}

const NotificationIconContainer = connect(
    (state, props) => ({notification: state.notification}),
    (dispatch) => ({dispatch})
)(NotificationIcon);

export default NotificationIconContainer;
