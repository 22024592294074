import React from 'react'
import LoadingSpinner from '../common/LoadingSpinner'
import CardSummary from './CardSummary'

const CardTable = ({cards, onCardClick, selectHighlightColor}) => {
    let body = <LoadingSpinner/>;
    if (!selectHighlightColor) {
        selectHighlightColor = () => { return null; }
    }
    if (cards.status === "fulfilled") {
        let cardElems = [];
        cards.payload.forEach((c) => {
            let onClick = () => { onCardClick(c); };
            cardElems.push(<li key={c.id} onClick={onClick}>
                <CardSummary data={c}
                             highlightColor={selectHighlightColor(c)}
                />
            </li>);
        });
        body = (
            <div className="pure-u-1">
                <ul className="inventory-list">
                    {cardElems}
                </ul>
            </div>
        );
    }
    return body;
}

export default CardTable;
