import React from 'react'

function LoadingSpinner() {
    return <
      img className="loading-spinner" src="/static/images/loading.gif"
      alt="loading spinner" style={{"width": "1em"}}
    />
}

export default LoadingSpinner
