import React from 'react'
import { connect } from 'react-redux'
import { getCards } from '../actions/cards'
import Card from '../components/inventory/Card'
import { openLightbox } from '../actions/lightbox'
import CardTable from '../components/inventory/CardTable'


class Inventory extends React.Component {
    componentDidMount() {
        getCards()(this.props.dispatch);
    }

    render () {
        return (
            <div className="pure-u-1">
                <h1>Inventory</h1>
                <div>
                    <CardTable
                        cards={this.props.cards}
                        onCardClick={this.getCardOnClick.bind(this)}
                    />
                </div>
            </div>
        );
    }

    getCardOnClick(card) {
        this.props.dispatch(openLightbox(<Card data={card}/>));
    }
}

const Container = connect(
    (state, props) => ({cards: state.cards}),
    (dispatch) => ({dispatch})
)(Inventory);


export default Container;
