export function arrayEq(l, r) {
    return (l.length === r.length) && l.every((e, i) => (e === r[i]));
}

export function ensureKey(obj, key) {
    if (obj[key] === undefined) {
        obj[key] = {};
    }
    return obj[key];
}

export function randomInt(maxInt, minInt = 0) {
    const difference = maxInt - minInt;
    const value = Math.floor(Math.random() * difference);
    return value + minInt;
}
