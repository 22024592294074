import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"

export const getDictionary = createRAAWithHandler(
    ActionTypes.DICTIONARY_REQUEST,
    (language) => {
        return request.get(`${window.config.apiRoot}/api/v1/language/${language}/dictionary/`);
    },
    (action, language) => {
        action.language = language;
        return action;
    }
);

export function dictionary(state={}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
      case ActionTypes.DICTIONARY_REQUEST:
        newState[action.language] = action;
        break;
      default:
        break;
    }
    return newState;
}
