import { createRAAWithHandler } from "../api/utils"
import request from "superagent"
import ActionTypes from "../actiontypes"

export const getNotifications = createRAAWithHandler(
    ActionTypes.NOTIFICATION_GET_REQUEST,
    () => {
        return request.get(`${window.config.apiRoot}/api/v1/_me/notification/`);
    },
    (action) => action
);

export function notification(state={}, action) {
    switch (action.type) {
        case ActionTypes.NOTIFICATION_GET_REQUEST:
            return action;
        default:
            break;
    }
    return state;
}
