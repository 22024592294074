import {login} from "../api/loginAPI"
import {getPoints} from "./points"
import ActionTypes from "../actiontypes"

export function authenticate(dispatch, authInfo) {
    if (authInfo.googleUser !== undefined) {
        let googleUser = authInfo.googleUser;
        let googleTokenId = googleUser.getAuthResponse().id_token;
        login(googleTokenId, (result) => {
            let accountName = result.name;
            let authToken = result.authToken;
            dispatch({
                type: ActionTypes.LOGIN,
                accountName,
                authToken
            });
            getPoints()(dispatch);
        });
    }
}
