import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducers from './reducers/main'
import persistence from './persistence'
import logger from './middleware/logger'

const store = createStore(
    reducers,
    applyMiddleware(persistence, thunk, logger)
);

export default store
