import ActionTypes from "../actiontypes"
import request from "superagent"
import {get as getLevelAPI, submitScore as submitScoreAPI} from "../api/level"
import { getEnergy } from './energy'
import { createRAAWithHandler } from '../api/utils'


export const getLevelInfo = createRAAWithHandler(
    ActionTypes.LEVEL_INFO_REQUEST,
    (language, level) => {
        return request.get(`${window.config.apiRoot}/api/v1/language/${language}/level/${level}/`);
    },
    (action) => action
);

export function getLevel(language, level) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.LEVEL_REQUEST
        });
        getLevelAPI(language, level, (data) => {
            dispatch({
                type: ActionTypes.LEVEL_RETRIEVED,
                data
            });
            getEnergy()(dispatch);
        });
    };
}

export function addOption(option) {
    return {
        type: ActionTypes.LEVEL_ADD_OPTION,
        option
    };
}

export function removeOption(option) {
    return {
        type: ActionTypes.LEVEL_REMOVE_OPTION,
        option
    };
}

export function submitQuestion() {
    return {
        type: ActionTypes.LEVEL_SUBMIT_QUESTION
    };
}

export function questionDecrementSeconds() {
    return {type: ActionTypes.LEVEL_QUESTION_DECREMENT_SECONDS};
}

export function setQuestion(index) {
    return { type: ActionTypes.LEVEL_SET_QUESTION, index };
}

export function completeLevel(language, key, level) {
    return (dispatch) => {
        dispatch({type: ActionTypes.LEVEL_COMPLETE_REQUESTED});
        submitScoreAPI(language, key, level.score, (result) => {
            dispatch({
                type: ActionTypes.LEVEL_COMPLETE_RECEIVED,
                result
            });
            dispatch({
                type: ActionTypes.POINTS_MODIFIED,
                delta: result.points_earned
            })
        });
    };
}

export function levelInfo(state={}, action) {
    switch (action.type) {
        case ActionTypes.LEVEL_INFO_REQUEST:
            return Object.assign({}, action);
        default:
            break;
    }
    return state;
}
