import ActionTypes from "../actiontypes"

export function inventorySelectStart(mainCard, completeCallback) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_BEGIN,
            mainCard,
            completeCallback
        });
    };
}


export function inventorySelectCardAdd(card) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_CARD_ADD,
            card
        });
    };
}

export function inventorySelectCardRemove(card) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_CARD_REMOVE,
            card
        });
    };
}

export function inventorySelectComplete() {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_COMPLETE,
        });
    };
}

export function inventorySelect(state={active: false, selected: []}, action) {
  let next = Object.assign({}, state);
  switch (action.type) {
    case ActionTypes.INVENTORY_SELECT_BEGIN:
      return {
        active: true,
        callback: action.callback,
        mainCard: action.mainCard,
        selected: []
      }
    case ActionTypes.INVENTORY_SELECT_CARD_ADD:
      if (action.card === state.mainCard) {
        return state;
      }
      if (next.selected.indexOf(action.card) === -1) {
        next.selected.push(action.card);
      }
      return next;
    case ActionTypes.INVENTORY_SELECT_CARD_REMOVE:
      if (action.card === state.mainCard) {
        return state;
      }
      const index = next.selected.indexOf(action.card);
      if (index !== -1) {
        next.selected.splice(index, 1);
      }
      return next;
    case ActionTypes.INVENTORY_SELECT_COMPLETE:
      // if this action is called, make
      // sure to perform your operation
      // when you call it.
      return {active: false};
    default:
      break;
  }
  return state;
}
