import { combineReducers } from 'redux'
import ActionTypes from '../actiontypes'
import { APIState } from '../api/utils'
import { languageCache } from './language'
import { allUsers } from './user'
import level from './level'
import { background } from '../actions/background'
import { flashcards } from '../actions/flashcards'
import { dictionary } from '../actions/dictionary'
import { flashcardsLevel } from '../actions/flashcardsLevel'
import { flashcardSet } from '../actions/flashcardSet'
import { lightbox } from '../actions/lightbox'
import { notification } from '../actions/notification'
import { energy } from '../actions/energy'
import { roll } from '../actions/roll'
import { cards } from '../actions/cards'
import { inventorySelect } from '../actions/inventorySelect'
import { levelInfo } from '../actions/level'


function points(state=0, action) {
    switch (action.type) {
        case ActionTypes.MODIFY_POINTS:
        case ActionTypes.POINTS_MODIFIED:
            state = action.delta + state;
            break;
        case ActionTypes.SET_POINTS:
            state = action.points;
            break;
        default:
            break;
    }
    return state
}

function showNav(state=false, action) {
    switch (action.type) {
        case ActionTypes.NAV_TOGGLE:
            return !state;
        case ActionTypes.NAV_OFF:
            return false;
        default:
            break;
    }
    return state;
}

function user(state={loggedIn: false}, action) {
    switch (action.type) {
        case ActionTypes.LOGIN:
            return Object.assign(
                {}, state, {loggedIn: true,
                            accountName: action.accountName,
                            authToken: action.authToken}
            )
        default:
            return state
    }
}

function userCache(state={}, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case ActionTypes.USER_DATA_ADD:
            newState[action.username] = action.data;
            break;
        case ActionTypes.USER_DATA_REQUEST:
            newState[action.username] = APIState.REQUESTED;
            break;
        case ActionTypes.USER_DATA_REMOVE:
            delete newState[action.username];
            break;
        default:
            break;
    }
    return newState;
}

const reducers = combineReducers({
    allUsers,
    background,
    cards,
    dictionary,
    energy,
    flashcards,
    flashcardSet,
    flashcardsLevel,
    inventorySelect,
    languageCache,
    level,
    levelInfo,
    lightbox,
    notification,
    points,
    roll,
    showNav,
    user,
    userCache,
})

export default reducers
