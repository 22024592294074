import ActionTypes from "../actiontypes"
import { ensureKey } from "../utils"

export function allUsers(state={}, action) {
  switch (action.type) {
    case ActionTypes.USER_SCORES_FOR_LANGUAGE_ADD:
      let newState = Object.assign({}, state);
      let userObject = ensureKey(newState, action.username);
      userObject[action.language] = action.data;
      return newState;
    default:
      break;
  }
  return state;
}
