import ActionTypes from '../actiontypes'
import { APIState } from '../api/utils'
import { arrayEq } from '../utils'
import { NO_SCORE_TIME_PERCENTAGE } from '../constants'

function calculateScore(questionState) {
    let score = questionState.max_score;
    // reduce score based on the amount between no score time percentage and one
    const secondsToRemove = NO_SCORE_TIME_PERCENTAGE * questionState.duration_seconds;
    const percentage = (
        (questionState.secondsLeft - secondsToRemove) * 1.0
        /
        (questionState.duration_seconds - secondsToRemove)
    );
    score *= (percentage >= 0 ? percentage : 0);
    return parseInt(score, 10);
}

// setup the state for a new question.
function setupStateForQuestion(state, index) {
    state.questionIndex = index;
    state.currentQuestion = state.questions[index];
    state.currentQuestion.state = "inProgress";
    state.currentQuestion.selectedOptions = [];
    state.currentQuestion.secondsLeft = state.currentQuestion.duration_seconds;
}

// submit, and evaluate the question
function questionSubmit(state) {
    state.currentQuestion.state = "done";
    state.currentQuestion.correct = arrayEq(
        state.currentQuestion.selectedOptions,  state.currentQuestion.answer
    );
    if (state.currentQuestion.correct) {
        state.score += calculateScore(state.currentQuestion);
    }
}


export default function level(state=null, action) {
    let newState = Object.assign({}, state);
    switch (action.type) {
        case ActionTypes.LEVEL_REQUEST:
            return APIState.REQUESTED

        case ActionTypes.LEVEL_RETRIEVED:
            newState = Object.assign({}, action.data);
            newState.score = 0;
            setupStateForQuestion(newState, 0);
            return newState;

        case ActionTypes.LEVEL_SET_QUESTION:
            if (newState.questionIndex !== action.index) {
                setupStateForQuestion(newState, action.index);
            }
            return newState;

        case ActionTypes.LEVEL_COMPLETE_REQUESTED:
            if (!newState.levelOver) {
                delete newState.questionIndex;
                newState.levelOver = true;
            }
            return newState;
        case ActionTypes.LEVEL_COMPLETE_RECEIVED:
            newState.result = action.result;
            return newState;
        case ActionTypes.LEVEL_ADD_OPTION:
            let indexL = newState.currentQuestion.selectedOptions.indexOf(action.option);
            if (indexL === -1) {
                newState.currentQuestion.selectedOptions.push(action.option);
            }
            return newState;

        case ActionTypes.LEVEL_REMOVE_OPTION:
            let indexLR = newState.currentQuestion.selectedOptions.indexOf(action.option);
            if (indexLR > -1) {
                newState.currentQuestion.selectedOptions.splice(indexLR, 1);
            }
            return newState;

        case ActionTypes.LEVEL_SUBMIT_QUESTION:
            questionSubmit(newState);
            return newState;

        case ActionTypes.LEVEL_QUESTION_DECREMENT_SECONDS:
            newState.currentQuestion.secondsLeft -= 1;
            if (newState.currentQuestion.secondsLeft <= 0) {
                questionSubmit(newState);
            }
            return newState;
        default:
            break;
    }
    return state;
}
