import React from 'react'
import { connect } from 'react-redux'
import { getFlashcards } from '../actions/flashcards'
import { getFlashcardSet  } from '../actions/flashcardSet'
import { getFlashcardStats } from '../actions/flashcardsStats'
import { getDictionary } from '../actions/dictionary'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../components/common/LoadingSpinner'
import CardSetInfo from '../components/flashcards/CardSetInfo'

// let flashCards = [{
//     name: "basics",
//     keys: ["foo", "bar"]
// }];

class FlashcardsManager extends React.Component {
    componentDidMount() {
        this.init(this.props.language, this.props.dispatch);
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.language !== this.props.language) {
            this.init(nextProps.language, nextProps.dispatch);
        }
    }

    init(language, dispatch) {
        getFlashcards(language)(dispatch);
        getFlashcardSet(language)(dispatch);
        getDictionary(language)(dispatch);
    }

    render () {
        if (
            !Object.keys(this.props.dictionary).length ||
            this.props.sets.status !== "fulfilled" ||
            this.props.flashcards.status !== "fulfilled"
        ) {
            return <LoadingSpinner/>;
        }
        let cardSetList = [];
        Object.keys(this.props.sets.payload).forEach((k) => {
            let cs = this.props.sets.payload[k];
            cardSetList.push(
                <CardSetInfo
                    key={cs.name}
                    language={this.props.language}
                    cardSetInfo={cs}
                    cards={this.props.flashcards.payload.cards}
                    dispatch={this.props.dispatch}
                />
            );
        });
        let stats = getFlashcardStats(this.props.flashcards.payload);
        return (
            <div className="pure-g">
                <h2 className="pure-u-3-4">Flashcards</h2>
                <div className="pure-u-1-4">
                    <Link to={`/language/${this.props.language}/flashcards/`} className="pure-button button-standard header-elem">
                        ({stats.numDue} due)
                    </Link>
                </div>
                <section className="pure-u-1">
                    <Link to={`/language/${this.props.language}/`}>
                        <h1>{this.props.language}: flashcards</h1>
                    </Link>
                    <div>
                        {cardSetList}
                    </div>
                </section>
            </div>
        );
    }
}

const container = connect(
    (state, props) => ({
        dictionary: state.dictionary[props.match.params.language] || {},
        flashcards: state.flashcards[props.match.params.language] || {},
        sets: state.flashcardSet[props.match.params.language] || {},
        language: props.match.params.language,
    }),
    (dispatch) => ({
        dispatch
    })
)(FlashcardsManager);

export default container;
