export function performRequest(request, callback) {
    let token = localStorage.getItem('authToken') || null;
    if (token) {
        request = request.set("Authorization", "Bearer " + token);
    }
    request.set("Content-Type", "application/json");
    request.end((err, res) => {
        if (err) {
            throw err;
        } else {
            let data = null;
            try {
                data = JSON.parse(res.text);
            } catch (e) {
                console.error(`unable to parse response from server for route ${res.req.url}.\nreceived:\n ${res.text}`);
            }
            if (callback != null) {
                callback(data);
            }
        }
    })
}

export const APIState = {
    REQUESTED: -1
}

// wraps up a lot of the logic that follows a standard pattern:
// 1. performing an API request
// 2. on success, returning the payload
// 3. on failure, calling performRequest's error function
export function createRAAWithHandler(ACTION_NAME, requestCreator, preDispatch, onSuccess) {
    return (...args) => (
        (dispatch) => {
            dispatch(preDispatch({
                type: ACTION_NAME,
                status: "pending"
            }, ...args));
            let request = requestCreator(...args);
            performRequest(request, (data) => {
                dispatch(preDispatch({
                    type: ACTION_NAME,
                    payload: data,
                    status: "fulfilled"
                }, ...args));
                if (onSuccess) {
                    onSuccess(dispatch, data, ...args);
                }
            });
        }
    );
}
