import React from 'react'
import { connect } from 'react-redux'
import { getFlashcards } from '../actions/flashcards'
import { getDictionary } from '../actions/dictionary'
import { startLevel, resetLevel } from '../actions/flashcardsLevel';
import { nextQuestion, endFlashcards } from '../actions/flashcardsLevel';
import { Link } from 'react-router-dom'
import Card from '../components/flashcards/Card'
import CardAnswer from '../components/flashcards/CardAnswer'
import './level.css'


class Flashcards extends React.Component {
    componentDidMount () {
        this.init(this.props.language, this.props.dispatch);
    }

    componentWillReceiveProps (nextProps) {
        if (nextProps.language !== this.props.language) {
            this.init(nextProps.language, nextProps.dispatch);
        }
        else if (nextProps.flashcards.status === "fulfilled" &&
            nextProps.dictionary.status === "fulfilled" &&
            !nextProps.level.started) {
                startLevel(
                    nextProps.flashcards.payload.due_cards,
                    nextProps.dictionary.payload
                )(nextProps.dispatch);
        }
    }

    componentWillUnmount() {
        resetLevel()(this.props.dispatch);
        if (this.props.level.remainingCards) {
            endFlashcards(this.props.language, this.props.level.completedCards)(
                this.props.dispatch
            );
        }
    }

    init(language, dispatch) {
        resetLevel()(dispatch);
        getFlashcards(language)(dispatch);
        getDictionary(language)(dispatch);
    }

    onNext () {
        if (this.props.level.remainingCards.length > 0) {
            nextQuestion()(this.props.dispatch);
        } else {
            endFlashcards(
                this.props.language,
                this.props.level.completedCards
            )(this.props.dispatch);
        }
    }

    render () {
        let body = <i className="fa fa-spinner"></i>;
        if (this.props.level.complete) {
            body = (
                <div className="pure-g">
                    <span className="pure-u-1">
                        COMPLETE!
                    </span>
                    <Link className="pure-u-1"
                          to={`/language/${this.props.language}/`}>
                        back to {this.props.language} home.
                    </Link>
                </div>

            );
        }
        else if (this.props.level.started) {
            body = (this.props.level.currentCard.correct === undefined) ?
                   <Card card={this.props.level.currentCard}
                         dispatch={this.props.dispatch}
                   />
                   :
                   <CardAnswer card={this.props.level.currentCard}
                               onClick={this.onNext.bind(this)}
                               dispatch={this.props.dispatch}
                   />;
            body =  (<span>
                       <div style={{textAlign: "left"}}>
                           <h2>{this.props.level.remainingCards.length} cards left</h2>
                       </div>
                       {body}
            </span>);

        }
        return (
            <div className="pure-g">
                <div className="pure-u-1 question-container">
                    {body}
                </div>
            </div>
        );
    }
}

const FlashcardsContainer = connect(
    (state, props) => ({
        dictionary: state.dictionary[props.match.params.language] || {},
        flashcards: state.flashcards[props.match.params.language] || {},
        language: props.match.params.language,
        level: state.flashcardsLevel
    }),
    (dispatch) => ({
        dispatch
    })
)(Flashcards);

export default FlashcardsContainer;
