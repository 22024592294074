import React from 'react'

function Timeline({characters}) {
    let outputItems = [];

    // let sortedByPriority = characters.sort((c) => -c.next_turn);
    for (var c of characters) {
        outputItems.push(
            <li key={c.name}>
                {c.name}
            </li>
        );
    }
    return (
        <div>
            <h2>Timeline</h2>
            <ul>
                {outputItems}
            </ul>
        </div>
    );
}

export default Timeline;
