import React from 'react'
import Timeline from '../components/battle/timeline'
import Grid from '../components/battle/grid'


let characters = [
    {
        name: "Yusuke",
        next_turn: 10,
        position: {x: 0, y: 0}
    },
    {
        name: "Parisa",
        next_turn: 24,
        position: {x: 2, y: 2}
    },
    {
        name: "Sora",
        next_turn: 15,
        position: {x: 3, y: 0}
    },
    {
        name: "Kenan",
        next_turn: 8,
        position: {x: 1, y: 4}
    },
];

const Battle = () => (
  <div className="pure-g">
      <section className="pure-u-1">
          <Timeline characters={characters} />
          <Grid characters={characters} />
      </section>
  </div>
)

export default Battle;
