// I don't know what the best name for this pattern is.
// this subscribes to the store and handles db persistence.
import ActionTypes from "./actiontypes"
import {modify as modifyAPI}  from './api/pointsAPI'
import {registerServiceWorker} from "./serviceworker"

export default ({getState}) => {
    return (next) => (action) => {
        switch (action.type) {
            case ActionTypes.MODIFY_POINTS:
                modifyAPI(action.delta);
                break;
            case ActionTypes.LOGIN:
                localStorage.setItem('authToken', action.authToken);
                // registerServiceWorker();
                break;
            default:
                break;
        }
        return next(action);
    }
}
