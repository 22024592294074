import React from 'react'
import { connect } from 'react-redux'
import { dismissLightbox } from '../actions/lightbox'
import './lightbox.css'


function LightBox({lightbox, dismiss}) {
    if (!lightbox.body) {
        return null;
    }
    return (
        <div>
            <div id="lightbox-container"
                 onClick={dismiss}>
            </div>
            <div id="lightbox">
                {lightbox.body}
            </div>
        </div>
    );
}

const LightBoxContainer = connect(
    (state, props) => {return {lightbox: state.lightbox}},
    (dispatch) => {
        return {
            dismiss: () => {
                dispatch(dismissLightbox());
            }
        }
    }
)(LightBox);

export default LightBoxContainer;
