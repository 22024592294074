import React from 'react'
import { connect } from 'react-redux'
import { requestRoll } from '../actions/roll'
import PointsDisplay from '../components/PointsDisplay'
import Card from '../components/inventory/Card'

class Roll extends React.Component {
    render () {
        let body = (
            <button className="pure-button pure-u-1"
                    onClick={this.requestRoll.bind(this)}>
                roll for {this.props.language} card (100 pts)
            </button>
        );
        if (this.props.roll.status === "fulfilled") {
            let payload = this.props.roll.payload;
            body = (
                <div className="pure-u-1">
                    <strong>You Received</strong>
                    <Card data={payload}/>
                    <button className="pure-button"
                            onClick={this.requestRoll.bind(this)}>
                        roll again (100 pts)
                    </button>
                </div>
            );
        }
        return (
            <div className="pure-g">
                <h1 className="pure-u-1">Rolling for {this.props.language}</h1>
                <span className="pure-u-1">
                    <PointsDisplay/> points remaining
                </span>
                {body}
            </div>
        );
    }

    requestRoll() {
        requestRoll(this.props.language)(this.props.dispatch);
    }
}

const Container = connect(
    (state, props) => ({
        roll: state.roll,
        language: props.params.language
    }),
    (dispatch) => ({dispatch})
)(Roll);

export default Container;
