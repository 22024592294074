import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import Nav from './components/nav/Nav'
import ActionTypes from './actiontypes'
import LightBox from './components/LightBox'
import './layout.css'

class Layout extends React.Component {
    render() {
        const style = {
            "backgroundImage": "url(" + this.props.background + ")"
        };
        return (
            <div id="layout" className={this.props.showNav ? "nav-active" : ""}>
                <LightBox/>
                <Nav/>
                <div id="main" className="container" style={style} onClick={this.onLinkClick.bind(this)}>
                    <div className="content">
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }

    onLinkClick() {
        if (this.props.showNav) {
            this.props.dispatch({type: ActionTypes.NAV_OFF});
        }
    }
}

const LayoutContainer = connect(
    (state, props) => {
      return {
          background: state.background,
          children: props.children,
          showNav: state.showNav,
      };
    },
    (dispatch) => ({dispatch})
)(Layout);

// withRouter is required to trigger component
// updates on layout. Redux sets up a shouldComponentUpdate
// barrier that doesn't consider the router unless this
// is added, regardless of whether this component is using
// anything within router or not.
export default withRouter(LayoutContainer);
