import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"
import request from "superagent"

export function startConsume(mainCard) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_BEGIN,
            mainCard
        });
    };
}

export function endConsume(mainCard, inventorySelect) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.INVENTORY_SELECT_COMPLETE
        });
    };
}

export const consumeSubmit = createRAAWithHandler(
    ActionTypes.CARD_CONSUME_REQUEST,
    (card_id, consume_ids) => (
        request.post(`${window.config.apiRoot}/api/v1/card/${card_id}/consume/`).send({
            consume_ids
        })
    ),
    (action) => action
);
