import React from 'react'
import {
    addOption, removeOption, submitQuestion, questionDecrementSeconds
} from '../../actions/level'

const INCORRECT_PAUSE = 3000;
const CORRECT_PAUSE = 1000;
// question time, in seconds
// const QUESTION_TIME_SECONDS = 10;

class Question extends React.Component {

    render() {
        let question = this.props.question;
        let cards = [];
        question.options.forEach((o) => {
            let className = ["level-card"];
            let onClick = this.addOption.bind(this);
            if (question.selectedOptions.indexOf(o) !== -1) {
                onClick = this.removeOption.bind(this);
                className.push("selected");
            }
            cards.push(<span onClick={onClick}
                             className={className.join(" ")}
                             data-option={o}
                             key={o}>{o}</span>)
        });
        let selectedCards = [];
        question.selectedOptions.forEach((o) => (
            selectedCards.push(<span onClick={this.removeOption.bind(this)}
                                     data-option={o}
                                     className="level-card"
                                     key={o}>{o}</span>)
        ));
        switch (question.state) {
            case "done":
                let timeout = (question.correct ?  CORRECT_PAUSE : INCORRECT_PAUSE);
                setTimeout(this.props.onNextQuestion, timeout);
                return (question.correct ?
                        <div className="pure-u-1 question-container">
                            <h1>Correct!</h1>
                            <span className="pure-button pure-button-primary" onClick={this.props.onNextQuestion}>
                                <i className="fa fa-2x fa-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div> :
                        <div className="pure-u-1 question-container">
                            <h1>Incorrect!</h1>
                            <h2>{question.answer.join(" ")}</h2>
                            <span className="pure-button pure-button-primary" onClick={this.props.onNextQuestion}>
                                <i className="fa fa-2x fa-arrow-right" aria-hidden="true"></i>
                            </span>
                        </div>);
            default:
                return (
                    <div className="pure-u-1 question-container block">
                        <div className="pure-g">
                            <h2 className="pure-u-1">{question.question}</h2>
                            <div className="pure-u-1 answer-row">
                                <span className="title">Your answer</span>: {selectedCards}
                            </div>
                            <div className="pure-u-1 question-row">
                                {cards}
                            </div>
                            <button className="pure-u-1 pure-button pure-button-primary confirm-button" type="button"
                                    onClick={this.submitQuestion.bind(this)} value="confirm">
                                confirm
                            </button>
                        </div>
                    </div>
                );
        }
    }

    addOption(node) {
        let option = node.target.dataset.option;
        this.props.dispatch(addOption(option));
    }

    removeOption(node) {
        let option = node.target.dataset.option;
        this.props.dispatch(removeOption(option));
    }

    submitQuestion() {
        this.props.dispatch(submitQuestion());
    }

    onSecond() {
        this.props.dispatch(questionDecrementSeconds());
    }

    componentDidMount() {
        this.timer = setInterval(this.onSecond.bind(this), 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timer);
    }
}

export default Question;
