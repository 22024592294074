import React from 'react'
import { setBackground, DEFAULT_IMAGE } from '../actions/background'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import {
    getLevel, setQuestion,
    completeLevel
} from '../actions/level'
import { APIState } from '../api/utils'
import Question from '../components/level/Question'
import CardSummary from '../components/inventory/CardSummary'

class Level extends React.Component {
    componentDidMount() {
        getLevel(this.props.language, this.props.name)(this.props.dispatch);
        setBackground("airport.jpg")(this.props.dispatch);
    }

    componentWillUnmount() {
        setBackground(DEFAULT_IMAGE)(this.props.dispatch);
    }

    render () {
        let body = <i className="fa fa-spinner"></i>;
        if (this.props.data && this.props.data !== APIState.REQUESTED) {
            if (this.props.data.levelOver) {
                let content = [];
                if (!this.props.data.result) {
                    content.push(
                        <section className="pure-u-1">
                            <h2>Submitting Results...</h2>);
                        </section>
                    );

                } else {
                    content.push(
                        <section className="pure-u-1">
                            <h2>Earned {this.props.data.result.points_earned} points!</h2>
                            <h2>Rank {this.props.data.result.rank}!</h2>
                            {this.props.data.result.rank !== "S" ?
                             <span className>
                                 Get a higher score and rank by answering question more accurately,
                                 and faster.
                             </span>
                             : ""
                            }
                             <h3>Earned a card:</h3>
                             <CardSummary data={this.props.data.result.card_earned}/>
                             <div className="pure-u-1">
                                <button className="pure-button button-standard"
                                        onClick={this.reloadLevel.bind(this)}>
                                    Play Again?
                                </button>
                             </div>
                             <div className="pure-u-1">
                                 <Link className="pure-button button-standard" to={`/language/${this.props.language}/`}>
                                     Back to {this.props.language}
                                 </Link>
                             </div>
                        </section>
                   );
                }

                body = (
                    <div className="pure-g">
                        <h1 className="pure-u-1">Level is Over!</h1>
                        <h2 className="pure-u-1">Score: {this.props.data.score}!</h2>
                        {content}
                  </div>
                );
            } else {
                let question = this.props.data.currentQuestion;
                let index = this.props.data.questionIndex;
                let dispatch = this.props.dispatch;
                body = (
                    <div className="pure-g">
                        <h2 className="pure-u-1-2 block">{this.props.data.questionIndex + 1} / {this.props.data.questions.length}</h2>
                        <h2 className="pure-u-1-2 right block">{question.secondsLeft}</h2>
                        <Question question={question} dispatch={dispatch}
                                  className="pure-u-1"
                                  onNextQuestion={this.nextQuestion(index).bind(this)}/>
                    </div>
                );
            }
        }
        return (
            <div className="pure-g">
                <div className="pure-u-1">
                    {body}
                </div>
            </div>
        );
    }

    nextQuestion(currentIndex) {
        return () => {
            if (this.props.data.questionIndex !== currentIndex) {
                return;
            }
            if (currentIndex === this.props.data.questions.length - 1) {
                this.props.dispatch(completeLevel(this.props.language,
                                                  this.props.name,
                                                  this.props.data));
            } else {
                let nextIndex = currentIndex + 1;
                this.props.dispatch(setQuestion(nextIndex));
            }
        };
    }

    reloadLevel() {
        getLevel(this.props.language, this.props.name)(this.props.dispatch);
    }
}

const mapStateToProps = (state, props) => {
    return {
        language: props.match.params.language,
        name: props.match.params.name,
        data: state.level
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        dispatch
    };
}

const LevelContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Level)

export default withRouter(LevelContainer)
