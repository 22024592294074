import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'

function LevelSelect({language, energyAvailable, userData, levels, dispatch}) {
    let levelComponents = levels.map((l) => (
        createLevelComponent(language, energyAvailable, userData, l)
    ));
    let nextLevelIndex = findNextLevelIndex(userData, levels);
    return (
        <div>
            <h2>Levels</h2>
            <h3>Next Level (less than rank B)</h3>
            {levelComponents[nextLevelIndex]}
            <h3>All Levels</h3>
            {levelComponents}
        </div>
    );
}

function findNextLevelIndex(userData, levels) {
    for (let i = 0; i < levels.length; i++) {
        let levelData = levels[i];
        let stats = getStats(userData, levelData);
        if (stats.rank > "B") {
            return i;
        }
    }
}

function createLevelComponent(language, energyAvailable, userData, levelData) {
    let stats = getStats(userData, levelData);
    let statsBlock = stats.rank + ": " + stats.score;
    let content = (
        <div key={levelData.name} className="detail-list-item">
            <img src="/static/images/levels/main.svg" alt="main"/>
            <strong>{levelData.name}</strong>
            <div className="upper-right">
                <i className="fa fa-bolt" aria-hidden="true"></i> {levelData.energy}
            </div>
            <div className="lower-right">
                <strong>Rank</strong> {statsBlock}
            </div>
            <div style={{clear: "both"}}/>
        </div>
    );
    if (energyAvailable >= levelData.energy) {
        content = (
            <Link to={`/language/${language}/level/${levelData.name}/`} key={levelData.name}>
                {content}
            </Link>
        );
    }
    return content;
}

function getStats(userData, levelData) {
    let stats = userData[levelData.name];
    let rank = stats ? stats.rank : "X";
    let score = stats ? stats.score : 0;
    return {rank, score};
}

const Container = connect(
    (state, props) => ({}),
    (dispatch) => ({dispatch})
)(LevelSelect);

export default Container;
