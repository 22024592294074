import { get as getUserAPI, getScoresForLanguage } from "../api/user"
import ActionTypes from "../actiontypes"

export function getUser(username) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_DATA_REQUEST,
            username
        });
        getUserAPI(username, (data) => (
            dispatch({
                type: ActionTypes.USER_DATA_ADD,
                username,
                data
            })
        ));
    };
}

export function getUserScoresForLanguage(username, language) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.USER_SCORES_FOR_LANGUAGE_REQUEST,
            username, language
        });
        getScoresForLanguage(username, language, (data) => {
            dispatch({
                type: ActionTypes.USER_SCORES_FOR_LANGUAGE_ADD,
                username, language, data
            });
        });
    }
}
