import React from 'react'
import {getBody} from './utils'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid';

export default function CardAnswer({card, dispatch, onClick}) {
    return (
        <Grid container spacing={24}>
          <Grid item xs={12}>
              <h2>{getBody(card.dictEntry, card.question)}</h2>
          </Grid>
          <Grid item xs={12}>
              {card.correct ? card.correct : "incorrect"}
          </Grid>
          { (!card.correct || card.correct === "almost") ?
            <Grid item xs={12}>
                Correct Answer: {getBody(card.dictEntry, card.answer)}
            </Grid> : ""
          }
          <Grid item xs={12}>
            <Button variant="contained" color="primary" onClick={onClick}>
              next
            </Button>
          </Grid>
        </Grid>
    );
}
