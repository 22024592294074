import React from 'react'


const About = () => (
  <div className="pure-g">
      <section className="pure-u-1">
          <h1>What is LangVerse?</h1>
          <p>
              HyperGlot is a language learning game, brought to you by KaitenLabs.
          </p>
          <p>
              Card images are from <a href="http://game-icons.net/">http://game-icons.net</a>, via the
              <a href="https://creativecommons.org/licenses/by/3.0/">Creative Commons BY 3.0 License</a>.
          </p>
      </section>
  </div>
)

export default About;
