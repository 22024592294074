import React from 'react'

const GRID_WIDTH = 5;
const GRID_HEIGHT = 5;

function Grid({characters}) {
    let grid = setupGrid();
    for (let c of characters) {
        grid[c.position.x][c.position.y] = c;
    }
    let tableRows = [];
    for (let rowIndex = 0; rowIndex < GRID_HEIGHT; rowIndex++) {
        let row = [];
        for (let colIndex = 0; colIndex < GRID_WIDTH; colIndex++) {
            let el = grid[colIndex][rowIndex];
            row.push(<td><GridElement el={el}/></td>);
        }
        tableRows.push(<tr>{row}</tr>);
    }
    return (<table>{tableRows}</table>);
}

function GridElement({el}) {
    if (!el) {
        return <span/>;
    }
    return (
        <span>
            {el.name}
        </span>
    );
}

function setupGrid() {
    let grid = [];
    for (let i = 0; i < GRID_HEIGHT; i++) {
        let column = [];
        for (let j = 0; j < GRID_WIDTH; j++) {
            column.push(null);
        }
        grid.push(column);
    }
    return grid;
}

export default Grid;
