import React from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import NotificationIcon from '../notification/NotificationIcon'
import EnergyBar from './EnergyBar'
import UserNav from './UserNav'
import ActionTypes from '../../actiontypes'
import { APP_NAME } from '../../constants'
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu'
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Toolbar from '@material-ui/core/Toolbar';
import Drawer from '@material-ui/core/Drawer';
import MenuItem from '@material-ui/core/MenuItem';
import { Home, Help, Info, Apps} from '@material-ui/icons';

class Nav extends React.Component {
    render() {
      return (
            <nav>
                <Drawer open={this.props.showNav} onClose={this.toggleNav.bind(this)}>
                    <MenuItem component={Link} to="/" onClick={this.toggleNav.bind(this)}>
                        <ListItemIcon>
                          <Home/>
                        </ListItemIcon>
                        {APP_NAME}
                    </MenuItem>
                    <UserNav onClick={this.onLinkClick.bind(this)}/>
                    <MenuItem component={Link} to="/inventory" onClick={this.toggleNav.bind(this)}>
                      <ListItemIcon>
                        <Apps/>
                      </ListItemIcon>
                      Inventory
                    </MenuItem>
                    <MenuItem onClick={() => {window.location.href = "/docs";}}>
                      <ListItemIcon>
                        <Help/>
                      </ListItemIcon>
                      Guide / Help
                    </MenuItem>
                    <MenuItem component={Link} to="/about" onClick={this.toggleNav.bind(this)}>
                      <ListItemIcon>
                        <Info/>
                      </ListItemIcon>
                      About
                    </MenuItem>
                </Drawer>
                <AppBar position="static" color="default" style={{'display': 'flex'}}>
                  <Toolbar>
                    <IconButton color="inherit" aria-label="Menu" onClick={this.toggleNav.bind(this)}>
                      <MenuIcon />
                    </IconButton>
                    <EnergyBar/>
                    <NotificationIcon/>
                  </Toolbar>
                </AppBar>
            </nav>
        );
    }

    navOff() {
        if (this.props.showNav) {
          this.props.dispatch({type: ActionTypes.NAV_OFF});
        }
    }

    toggleNav(e) {
        this.props.dispatch({type: ActionTypes.NAV_TOGGLE});
    }

    onLinkClick() {
        if (this.props.showNav) {
            this.props.dispatch({type: ActionTypes.NAV_OFF});
        }
    }
}

const NavContainer = connect(
    (state, props) => ({showNav: state.showNav}),
    (dispatch) => ({dispatch})
)(Nav);

export default NavContainer;
