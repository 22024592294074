import React from "react"
import { openLightbox, dismissLightbox } from "./lightbox"

export const confirm = (dispatch, body, onConfirm) => {
    let onConfirmWrapper = () => {
        dispatch(dismissLightbox());
        onConfirm();
    };
    let lightboxContent = (
        <div>
            {body}
            <button className="pure-button pure-button-primary pure-u-1"
                    onClick={onConfirmWrapper}>
                Confirm
            </button>
        </div>
    );
    dispatch(openLightbox(lightboxContent));
}
