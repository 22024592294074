import React from 'react'
import PointsDisplay from '../PointsDisplay'
import GoogleLogin from '../auth/GoogleLogin'
import { authenticate } from '../../actions/login'
import { connect } from 'react-redux'
import { AccountBox }from '@material-ui/icons';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import MenuItem from '@material-ui/core/MenuItem';

const UserNav = ({user, onGoogleLogin, onClick}) => {
    if (user.loggedIn) {
        return (
            <MenuItem onClick={() => onClickWrapper(user, onClick)}>
              <ListItemIcon>
                <AccountBox/>
              </ListItemIcon>
                {user.accountName} (<PointsDisplay/>)
            </MenuItem>
        )
    } else {
        return (
            <MenuItem>
                <GoogleLogin onSignIn={onGoogleLogin}/>
            </MenuItem>
        )
    }
}

function onClickWrapper(user, nextHandler) {
    window.history.pushState({}, "profile", `/user/${user.accountName}/`);
    nextHandler();
}

const stateToProps = (state, props) => {
    return {
        user: state.user,
        onClick: props.onClick
    }
}

const dispatchToProps = (dispatch) => ({
    onGoogleLogin: (googleUser) => (
        authenticate(dispatch, {googleUser})
    )
})

const Container = connect(
    stateToProps,
    dispatchToProps
)(UserNav)

export default Container;
