export function getBody(entry, type) {
    if (type === "source") {
        return fromSource(entry);
    }
    else if (type === "target") {
        return fromTarget(entry);
    }
}

function fromSource(dictionaryEntry) {
    let word = dictionaryEntry.key;
    /* if (dictionaryEntry.ipa !== null) {
        word += ` (${dictionaryEntry.ipa})`;
    } */
    return word;
}

function fromTarget(dictionaryEntry) {
  if (dictionaryEntry.definition !== null) {
    return dictionaryEntry.definition;
  }
  return buildCardString(dictionaryEntry.translations);
}

function buildCardString(objectOrString) {
    if (typeof objectOrString === "object") {
        let words = [];
        for (let word in objectOrString) {
            let wordString = word;
            let details = [];
            let features = objectOrString[word]["features"];
            for (let name in features) {
                let value = features[name];
                details.push(`${name}: ${value}`);
            }
            if (details.length > 0) {
                wordString += ` (${details.join(',')})`;
            }
            words.push(wordString);
        }
        return words.join("/");
    } else {
        return objectOrString;
    }
}
