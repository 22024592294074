import React from 'react'
import { connect } from 'react-redux'
import { getUser } from '../actions/user'
import { LanguageActions } from '../actions/language'
import { APIState } from '../api/utils'


class User extends React.Component {
    componentDidMount() {
        getUser(this.props.username)(this.props.dispatch);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.username !== nextProps.username) {
            LanguageActions.get(nextProps.username)(this.props.dispatch);
        }
    }

    render () {
        switch (this.props.userData) {
            case APIState.REQUESTED:
                return <h1>loading..</h1>;
            case null:
            case undefined:
                return <h1>requesting..</h1>;
            default:
                return (
                    <div className="pure-g">
                        <div className="pure-u-1">
                            <h1>{this.props.username}</h1>
                            <strong>{this.props.userData.points} points</strong>
                        </div>
                    </div>
                );
        }
    }
}

const mapStateToProps = (state, props) => {
  return {
      username: props.params.username,
      userData: state.userCache[props.params.username]
  }
}

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
}

const UserContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(User)

export default UserContainer
