const ActionTypes = {
    BACKGROUND_MODIFY: "BACKGROUND_MODIFY",
    CARD_REQUEST: "CARD_REQUEST",
    CARD_CONSUME_REQUEST: "CARD_CONSUME_REQUEST",
    DICTIONARY_REQUEST: "DICTIONARY_REQUEST",
    ENERGY_REQUEST: "ENERGY_REQUEST",
    ENERGY_USE_REQUEST: "ENERGY_USE_REQUEST",
    FLASHCARD_REQUEST: "FLASHCARD_REQUEST",
    FLASHCARD_SET_REQUEST: "FLASHCARD_SET_REQUEST",
    FLASHCARD_LEVEL_START: "FLASHCARD_LEVEL_START",
    FLASHCARD_LEVEL_SUBMIT_ANSWER: "FLASHCARD_LEVEL_SUBMIT_CARD",
    FLASHCARD_LEVEL_COMPLETE_REQUEST: "FLASHCARD_LEVEL_COMPLETE_REQUEST",
    FLASHCARD_SUBMIT_REQUEST: "FLASHCARD_SUBMIT_REQUEST",
    FLASHCARD_LEVEL_NEXT_CARD: "FLASHCARD_LEVEL_NEXT_CARD",
    FLASHCARD_LEVEL_RESET: "FLASHCARD_LEVEL_RESET",
    FLASHCARD_ADD_REQUEST: "FLASHCARD_ADD_REQUEST",
    FLASHCARD_ADD_SET_REQUEST: "FLASHCARD_ADD_SET_REQUEST",
    INVENTORY_SELECT_BEGIN: "INVENTORY_SELECT_BEGIN",
    INVENTORY_SELECT_CARD_ADD: "INVENTORY_SELECT_CARD_ADD",
    INVENTORY_SELECT_CARD_REMOVE: "INVENTORY_SELECT_CARD_REMOVE",
    INVENTORY_SELECT_COMPLETE: "INVENTORY_SELECT_COMPLETE",
    LANGUAGE_REQUEST: "LANGUAGE_REQUEST",
    LANGUAGE_ADD: "LANGUAGE_RETRIEVED",
    LEVEL_INFO_REQUEST: "LEVEL_INFO_REQUEST",
    LEVEL_REQUEST: "LEVEL_REQUEST",
    LEVEL_RETRIEVED: "LEVEL_RETRIEVED",
    LEVEL_SET_QUESTION: "LEVEL_SET_QUESTION",
    LEVEL_COMPLETE_REQUESTED: "LEVEL_COMPLETE_REQUESTED",
    LEVEL_COMPLETE_RECEIVED: "LEVEL_COMPLETE_RECEIVED",
    LEVEL_ADD_OPTION: "LEVEL_ADD_OPTION",
    LEVEL_REMOVE_OPTION: "LEVEL_REMOVE_OPTION",
    LEVEL_SUBMIT_QUESTION: "LEVEL_SUBMIT_QUESTION",
    LEVEL_QUESTION_DECREMENT_SECONDS: "LEVEL_QUESTION_DECREMENT",
    LIGHTBOX_DISMISS: "LIGHTBOX_DISMISS",
    LIGHTBOX_OPEN: "LIGHTBOX_OPEN",
    LOGIN: "LOGIN",
    MODIFY_POINTS: "MODIFY_POINTS",
    NAV_TOGGLE: "NAV_TOGGLE",
    NAV_OFF: "NAV_OFF",
    NOTIFICATION_GET_REQUEST: "NOTIFICATIONS_GET_REQUEST",
    // POINTS_MODIFIED is passive: it means points were modified externally.
    POINTS_MODIFIED: "POINTS_MODIFIED",
    ROLL_REQUEST: "ROLL_REQUEST",
    SET_POINTS: "SET_POINTS",
    USER_DATA_REQUEST: "USER_DATA_REQUEST",
    USER_DATA_ADD: "USER_DATA_ADD",
    USER_DATA_REMOVE: "USER_DATA_REMOVE",
    USER_SCORES_FOR_LANGUAGE_REQUEST: "USER_SCORES_FOR_LANGUAGE_REQUEST",
    USER_SCORES_FOR_LANGUAGE_ADD: "USER_SCORES_FOR_LANGUAGE_REQUEST_ADD",
    USER_SCORES_FOR_LANGUAGE_DELETE: "USER_SCORES_FOR_LANGUAGE_DELETE",
}

export default ActionTypes
