import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"

export const getCards = createRAAWithHandler(
    ActionTypes.CARD_REQUEST,
    () => {
        return request.get(`${window.config.apiRoot}/api/v1/user/_me/card/`);
    },
    (action) => action
);

export function cards(state={}, action) {
    switch (action.type) {
        case ActionTypes.CARD_REQUEST:
            let newState = Object.assign({}, state, action);
            return newState;
        default:
            break;
    }
    return state;
}
