import request from "superagent"
import ActionTypes from "../actiontypes"
import { createRAAWithHandler } from "../api/utils"

export const getEnergy = createRAAWithHandler(
    ActionTypes.ENERGY_REQUEST,
    () => {
        return request.get(`${window.config.apiRoot}/api/v1/user/_me/energy/`);
    },
    (action) => action
);

export const useEnergy = createRAAWithHandler(
    ActionTypes.ENERGY_USE_REQUEST,
    (amount) => {
        return request.post(`${window.config.apiRoot}/api/v1/user/_me/energy/use/`)
                      .send({amount});
    },
    (action, amount) => {
        action.amount = amount;
        return action;
    }
);

export function energy(state={}, action) {
    switch (action.type) {
        case ActionTypes.ENERGY_REQUEST:
            let newState = Object.assign({}, state, action);
            return newState;
        default:
            break;
    }
    return state;
}
