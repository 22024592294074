import React from 'react'
import { connect } from 'react-redux'
import { getEnergy } from '../../actions/energy'
import LoadingSpinner from '../common/LoadingSpinner'
import {Favorite} from '@material-ui/icons';

class EnergyBar extends React.Component {
    componentWillMount() {
        this._updateSecondsId = setInterval(this.updateSecondsLeft.bind(this), 1000);
        this.setState({
            "secondsUntilEnergy": null
        });
    }

    componentDidMount() {
        this.refreshEnergy();
    }

    componentWillUnmount() {
        clearInterval(this._updateSecondsId);
    }

    render() {
        let energy = this.props.energy;
        if (!energy.payload) {
            return <LoadingSpinner/>;
        }
        return (
            <h2 style={{'flexGrow': '1', 'margin': '0'}}>
                {energy.payload.energy} / {energy.payload.max_energy} <Favorite/>
            </h2>
        );
    }

    refreshEnergy() {
        getEnergy()(this.props.dispatch);
    }

    updateSecondsLeft() {
        let secondsUntilEnergy = null;
        let energy =  this.props.energy;
        if (energy.payload && energy.payload.next_energy_received) {
            let nextEnergyRelease = new Date(energy.payload.next_energy_received);
            let now = new Date();
            let secondsLeft = Math.round((nextEnergyRelease - now) / 1000);
            if (secondsLeft > 0) {
                secondsUntilEnergy = "(next point: " + Math.round((nextEnergyRelease - now) / 1000) + ")";
            } else {
                this.refreshEnergy();
            }
        }
        this.setState({secondsUntilEnergy});
    }
}

const Container = connect(
    (state, props) => ({energy: state.energy}),
    (dispatch) => ({dispatch})
)(EnergyBar);

export default Container;
