export function getFlashcardStats(flashcards) {
    return {
        numDue: Object.values(flashcards.due_cards).length,
        numMastered: flashcards.mastered,
        total: flashcards.total
    };
}

// function getNumMastered(flashcards) {
//     return Object.keys(flashcards.cards).reduce((pre, cur) => (
//         pre + (flashcards.cards[cur].mastered ? 1 : 0)
//     ), 0);
// }
