import React from 'react'
import { connect } from 'react-redux'
import { getFlashcardStats } from '../../actions/flashcardsStats'
import { AddBox } from '@material-ui/icons';
import { withRouter } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';



function Summary({language, flashcards, dispatch, history}) {
    if (!flashcards) {
        return (<div/>);
    }
    let stats = getFlashcardStats(flashcards);
    let completedPercentage = (stats.numMastered / stats.total) * 100;
    if (stats.total === 0) {
        completedPercentage = 100;
    }
    return (
        <div className="pure-g">
            <h2 className="pure-u-1-2">Flashcards</h2>
            <div className="pure-u-1-4">
                <Button variant="raised"
                    onClick={() => {
                        history.push(`/language/${language}/flashcards/`);
                    }}>
                    {`${stats.numDue} due`}
                </Button>
            </div>
            <div className="pure-u-1-4">
                <Button variant="raised"
                        onClick={() => {
                            history.push(`/language/${language}/flashcards/manage`);
                        }}>
                  <AddBox/>
                </Button>
            </div>
            <div className="pure-u-2-3">
                <LinearProgress mode="determinate" value={completedPercentage}/>
            </div>
            <span className="pure-u-1-3">{completedPercentage}% mastered</span>
        </div>
    );
}

const Container = connect(
    (state, props) => ({
      history: props.history
    }),
    (dispatch) => ({dispatch})
)(Summary);

export default withRouter(Container);
