import request from "superagent"
import { performRequest } from './utils'

export function get(language, level, cb) {
    performRequest(request.post(`${window.config.apiRoot}/api/v1/language/${language}/level/${level}/`), cb);
}

export function submitScore(language, level, score, cb) {
    performRequest(request.post(
        `${window.config.apiRoot}/api/v1/user/_me/language/${language}/level/${level}/`
    ).send({score}), cb)
}
