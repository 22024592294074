import React from 'react'
import { connect } from 'react-redux'
import { getFlashcards, addFlashcards } from '../actions/flashcards'
import { LanguageActions } from '../actions/language'
import { setBackground, DEFAULT_IMAGE } from '../actions/background'
import { getUserScoresForLanguage } from '../actions/user'
import { APIState } from '../api/utils'
import { withRouter } from 'react-router-dom'
import FlashcardSummary from '../components/flashcards/FlashcardSummary'
import LevelSelect from '../components/language/LevelSelect'
import Button from '@material-ui/core/Button';


class Language extends React.Component {
    componentDidMount() {
        LanguageActions.get(this.props.language)(this.props.dispatch);
        getUserScoresForLanguage("_me", this.props.language)(this.props.dispatch);
        getFlashcards(this.props.language)(this.props.dispatch);
        setBackground("airport.jpg")(this.props.dispatch);
    }

    componentWillUnmount() {
        setBackground(DEFAULT_IMAGE)(this.props.dispatch);
    }

    componentWillReceiveProps(nextProps) {
        if (this.props.language !== nextProps.language) {
            LanguageActions.get(nextProps.language)(this.props.dispatch);
        }
    }

    addFlashcardsFromDict() {
        addFlashcards(this.props.language, {fromDictionary: true})(this.props.dispatch);
    }

    render () {
        switch (this.props.data) {
            case APIState.REQUESTED:
                return <h1>loading..</h1>;
            case null:
            case undefined:
                return <h1>requesting..</h1>;
            default:
                let energyAvailable = 0;
                if (this.props.energy) {
                    energyAvailable = this.props.energy.energy;
                }
                let languagePath = `/language/${this.props.language}/roll/`;
                return (
                    <div className="pure-g">
                        <section className="pure-u-1">
                            <h1 style={{"textTransform": "capitalize"}}>{this.props.data.name}</h1>
                        </section>
                        <section className="pure-u-1">
                            <Button label="Roll For Cards" variant="raised"
                                    onClick={() => {this.props.history.push(languagePath);}}
                            >
                              Roll For Cards
                            </Button>

                        </section>
                        { this.props.flashcards ?
                          (
                              <section className="pure-u-1">
                                  <FlashcardSummary language={this.props.language}
                                                    flashcards={this.props.flashcards.payload} />

                              </section>
                          ) : ""
                        }
                        <section className="pure-u-1">
                            { this.props.userData ?
                              <LevelSelect language={this.props.language}
                                           userData={this.props.userData}
                                           levels={this.props.data.levels}
                                           energyAvailable={energyAvailable}
                              /> : ""
                            }
                        </section>
                    </div>
                );
        }
    }
}

const mapStateToProps = (state, props) => {
    let retVal = {
        data: state.languageCache[props.match.params.language],
        energy: state.energy.payload,
        flashcards: state.flashcards[props.match.params.language],
        history: props.history,
        language: props.match.params.language,
        userData: {}
    };
    if (state.allUsers._me !== undefined) {
        retVal.userData = state.allUsers._me[props.match.params.language];
    }
    return retVal;
}

const mapDispatchToProps = (dispatch) => {
    return {dispatch};
}

const LanguageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Language)

export default withRouter(LanguageContainer);
