import React from 'react'
import Notification from './Notification'


export default function NotificationList({notifications, onNavigate}) {
    const elems = [];
    for (let i in notifications) {
        let n = notifications[i];
        elems.push(
            <Notification key={i} notification={n} onNavigate={onNavigate}/>
        );
    }
    return (
        <ul className="notificationList">
           {elems}
        </ul>
    );
}
