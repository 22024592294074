import React from 'react'
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { submitAnswer } from '../../actions/flashcardsLevel'
import { openLightbox } from '../../actions/lightbox'
import { getBody } from './utils'
import './card.css'

class Card extends React.Component {

    componentWillReceiveProps(nextProps) {
        if (this.props.card !== nextProps.card) {
            this.refs.answer.value = "";
        }
    }

    showCardDetails(o, e) {
        e.stopPropagation();
        console.log(o);
        this.props.dispatch(openLightbox((<span>
            <h2>{o.key}</h2>
            <h3>Additional Data</h3>
            <span>
                {JSON.stringify(o.additional_data, null, 2)}
            </span>
            <h3>IPA</h3>
            <span>
                {o.ipa}
            </span>
            {/* <h3>Features</h3>
            <span>
              {JSON.stringify(o.translations, null, 2)}
            </span> */}
        </span>)));
        //  JSON.stringify(o)
    }

    render() {
        let choices = [];
        let options = new Set();
        this.props.card.options.forEach((o) => {
            if (o === undefined) {
                console.error(`undefined option found! card in question:`);
                console.error(this.props.card);
            }
            if (!options.has(o)) {
                choices.push(
                    <Tooltip title={JSON.stringify(o.additional_data, null, 2)} key={o.key}>
                        <Button variant="outlined"
                            className="level-card"
                            onClick={(() => { submitAnswer(o)(this.props.dispatch) })}>
                            {getBody(o, this.props.card.answer)} <i className="fa fa-question-circle fa-2"
                                aria-hidden="true"
                                onClick={((e) => { this.showCardDetails(o, e) })}></i>
                        </Button>
                    </Tooltip>
                );
            }
        });
        return (
            <div className="flashcard-card pure-g">
                <div className="card-key pure-u-1">
                    <h2>{getBody(this.props.card.dictEntry, this.props.card.question)}</h2>
                </div>
                <div className="pure-u-1 question-row">
                    {choices}
                </div>
                {/* <div className="pure-u-1">
                    <input type="text" placeholder="type answer" name="answer" ref="answer"/>
                </div> */}
                {/* <input className="pure-u-1 pure-button pure-button-primary confirm-button"
                       onClick={
                           (() => {
                               submitAnswer(this.refs.answer.value)(this.props.dispatch)
                           }).bind(this)
                       }
                       type="button" value="submit" /> */}
            </div>
        );
    }
}

export default Card;
