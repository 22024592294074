import React from 'react'

class ValueInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {value: ""};
  }

    render () {
        return (
            <fieldset>
                <legend>{this.props.name}</legend>
                <input type="text" placeholder={this.props.placeholder}
                       value={this.state.value}
                       onChange={this.handleChange.bind(this)}
                />
                <input className="pure-button pure-button-primary" type="button"
                       value={this.props.buttonName} onClick={this.submit.bind(this)}
                />
            </fieldset>
        );
    }

    handleChange (event) {
        this.setState({value: event.target.value});
    }

    submit(e) {
        this.props.onSubmit(this.state.value);
        this.setState(this.getInitialState());
    }
}

export default ValueInput;
