import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Provider} from 'react-redux';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import blue from '@material-ui/core/colors/blue';
import store from './store';
import Layout from './layout';
import About from './pages/About'
import Admin from './pages/Admin'
import Battle from './pages/Battle'
import CardConsume from './pages/CardConsume'
import Flashcards from './pages/Flashcards'
import FlashcardsManager from './pages/FlashcardsManager'
import Index from './pages/Index'
import Inventory from './pages/inventory'
import Language from './pages/Language'
import Level from './pages/Level'
import LevelInfo from './pages/LevelInfo'
import Roll from './pages/Roll'
import User from './pages/User'

window.config = {
  google_auth: {
    client_id: process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID,
    serviceworker: {
        publicKey: process.env.REACT_APP_GOOGLE_AUTH_SERVICEWORKER_PUBLIC_KEY
    }
  },
  apiRoot: process.env.REACT_APP_API_ROOT
};

const theme = createMuiTheme({
  palette: {
    primary: blue
  },
});



class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <MuiThemeProvider theme={theme}>
          <BrowserRouter>
            <Layout>
              <Route exact path="/" component={Index}/>
              <Route exact path="/about" component={About}/>
              <Route exact path="/battle" component={Battle}/>
              <Route exact path="/daimyo" component={Admin}/>
              <Route exact path="/inventory/" component={Inventory}/>
              <Route exact path="/inventory/combine/:cardId" component={CardConsume}/>
              <Route exact path="/language/:language/" component={Language}/>
              <Route exact path="/language/:language/flashcards/" component={Flashcards}/>
              <Route exact path="/language/:language/flashcards/manage" component={FlashcardsManager}/>
              <Route exact path="/language/:language/level/:name/info" name="levelinfo" component={LevelInfo}/>
              <Route exact path="/language/:language/level/:name/" name="level" component={Level}/>
              <Route exact path="/language/:language/roll/" component={Roll}/>
              <Route exact path="/user/:username/" component={User}/>
            </Layout>
          </BrowserRouter>
        </MuiThemeProvider>
      </Provider>
    );
  }
}

export default App;
